<template>
  <div id="on-demand-header">
    <a @click="this.section = 'home'">Home</a>
    <a @click="this.section = 'all'">All</a>
    <a @click="this.section = 'search'"><i class='fas fa-search'></i></a>
  </div>

  <br /><br /><br /><br /><br /><br /><br /><br />

  <div id="onDemand">
    <main v-if="section == 'home'">
      <section id="recent" class="videoSection feature">
        <div class="video-section-header">
          <h2>Latest Uploads</h2>
          <a @click="setOpen(recents)"><a class="fas fa-chevron-right"></a></a>
        </div>
        <div class="videoList" ref="mainVideos">
          <div v-for="video in recents.videos" :key="video._id" class="video"
            @click="this.$router.push(`/watch/${video._id}`)">
            <img :src="video.thumbnail" alt="Video thumbnail" />
            <h3>{{ video.title }}</h3>
            <!-- <p class="description">{{ video.description }}</p> -->
            <span class="info">
              <p v-if="video.difficulty">Difficulty: {{ video.difficulty }}</p>
              <p>{{ new Date(video.date).toLocaleDateString().substring(0, 9) }}</p>
            </span>
          </div>
        </div>
        <div v-if="loading" class="fas fa-spinner"></div>
      </section>

      <section v-for="collection in collections" class="videoSection">
        <div class="video-section-header">
          <h2>{{ collection.title }}</h2>
          <a @click="setOpen(collection)"><a class="fas fa-chevron-right"></a></a>
        </div>
        <div class="videoList"><!-- :class="{ open: this.openCollection == collection }"> -->
          <div v-for="video in collection.videos" :key="video._id" class="video"
            @click="this.$router.push(`/watch/${video._id}`)">
            <img :src="video.thumbnail" alt="Video thumbnail" />
            <h3>{{ video.title }}</h3>
          </div>
        </div>
      </section>
    </main>

    <main v-if="section == 'all'" id="allSection">
      <section class="browse">
        <div class="video-section-header">
          <h2>Browse All Videos</h2>
        </div>
        <div class="videoList open no-scroll" ref="mainVideos">
          <div v-for="video in videos" :key="video._id" class="video" @click="this.$router.push(`/watch/${video._id}`)">
            <img :src="video.thumbnail" alt="Video thumbnail" />
            <h3>{{ video.title }}</h3>
            <!-- <p class="description">{{ video.description }}</p> -->
            <span class="info">
              <p v-if="video.difficulty">Difficulty: {{ video.difficulty }}</p>
              <p>{{ new Date(video.date).toLocaleDateString().substring(0, 9) }}</p>
            </span>
            <div class="tags">
              <i v-for="tag in video.tags" :key="tag">{{ tag }}</i>
            </div>
          </div>
        </div>
        <div v-if="loading" class="fas fa-spinner"></div>
      </section>
    </main>

    <main v-if="section == 'search'">
      <br /><br />
      <form id="searchBar" @submit.prevent="searchVideos">
        <input type="text" v-model="search" placeholder="Search for a video..." />
        <i @click.prevent="searchVideos"><a class="fas fa-search"></a></i>
      </form>

      <section id="searchResults" v-if="searched">
        <div class="video-section-header">
          <h2>Search Results</h2>
          <a @click="searched = false; this.search = ''">Clear <i class="fas fa-times"></i></a>
        </div>
        <div class="videoList open no-scroll">
          <div v-for="video in searchResults" :key="video._id" class="video"
            @click="this.$router.push(`/watch/${video._id}`)">
            <img :src="video.thumbnail" alt="Video thumbnail" />
            <h3>{{ video.title }}</h3>
            <p class="description">{{ video.description }}</p>
            <span class="info">
              <p v-if="video.difficulty">Difficulty: {{ video.difficulty }}</p>
              <p>{{ new Date(video.date).toLocaleDateString().substring(0, 9) }}</p>
            </span>
            <div class="tags">
              <i v-for="tag in video.tags" :key="tag">{{ tag }}</i>
            </div>
          </div>
          <div v-if="!searchResults?.length && !searchLoading">
            <p>No results found...<br /><br /><br /></p>
          </div>
          <div v-if="searchLoading" class="fas fa-spinner"></div>
        </div>
      </section>

      <div v-else id="search-i">
        <i>Search for a video</i>
      </div>
    </main>
  </div>

  <section id="openCollection" :class="{ open: openCollection }">
    <div v-if="openCollection">
      <br />
      <br />
      <div id="openCollectionHeader">
        <h2>{{ openCollection.title }}</h2>
        <a @click="closeCollection()"><a class="fas fa-close"></a> </a>
      </div>
      <br />
      <div class="videoList open">
        <div v-for="video in openCollection.videos" :key="video._id" class="video"
          @click="this.$router.push(`/watch/${video._id}`)">
          <img :src="video.thumbnail" alt="Video thumbnail" />
          <h3>{{ video.title }}</h3>
          <p class="description">{{ video.description }}</p>
          <span class="info">
            <p v-if="video.difficulty">Difficulty: {{ video.difficulty }}</p>
            <p>{{ new Date(video.date).toLocaleDateString().substring(0, 9) }}</p>
          </span>
          <div class="tags">
            <i v-for="tag in video.tags" :key="tag">{{ tag }}</i>
          </div>
        </div>
        <div v-if="!openCollection.videos?.length">
          <p>There aren't any videos here yet...</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  getVideos,
  getCollections,
  searchVideos
} from '@/app/routes';

let loadingVideos = false;

export default {
  name: 'OnDemandView',
  data() {
    return {
      section: 'home',
      search: '',
      searched: false,
      searchResults: [],
      searchLoading: false,
      recents: {
        title: 'Latest Uploads',
        videos: []
      },
      videos: [],
      collections: [],
      openCollection: null,
      position: 0,
      videoEnd: false,
      favourites: [],
      watched: [],
      openRecent: false,
      openFavourites: false,
      loading: false,
      mobile: window.innerWidth < 800
    };
  },
  methods: {
    async getVideos() {
      const response = await getVideos(this.position, 20);
      if (response) {
        this.videos = [...this.videos, ...response];
        if (this.recents.videos.length < 10) this.recents.videos = response.slice(0, 10);
        if (response.length < 1) this.videoEnd = true;
      } else console.error('Error getting videos');
    },
    async getCollection(id) {
      const response = await getCollection(id);
      if (response) {
        this.videos = response;
      } else console.error('Error getting collection');
    },
    async getCollections() {
      const response = await getCollections();
      if (response) {
        const customOrder = ["Step Boost", "Strength & Conditioning", "Mind Body", "Total Body", "Cardio & HIIT", "Add On Mini Workouts"];

        response.sort((a, b) => {
          return customOrder.indexOf(a.title) - customOrder.indexOf(b.title);
        });
        this.collections = response;
      } else console.error('Error getting collections');
    },
    async searchVideos() {
      if (this.search == '') {
        this.searched = false;
        return;
      }
      this.searchLoading = true;
      this.searched = true;
      this.searchResults = [];
      const response = await searchVideos(this.search);
      if (response) {
        this.searchResults = response;
      } else console.error('Error searching videos');
      this.searchLoading = false;
    },
    async handleScroll(event) {
      if (this.section !== 'all' || loadingVideos) return;
      const elementHeight = document.getElementById('allSection').clientHeight;
      const atBottom = event.detail.currentY + 750 > elementHeight;
      if (atBottom && !this.videoEnd) {
        this.position += 20;
        loadingVideos = true;
        await this.getVideos();
        loadingVideos = false;
      }
    },
    setOpen(collection) {
      this.openCollection = collection;
    },
    closeCollection() {
      this.openCollection = null;
    }
  },
  mounted() {
    this.loading = true;
    this.getVideos();
    this.getCollections();
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

body section .videoList {
  flex-direction: row;
  overflow-x: auto;
}

#on-demand-header {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $colourS;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  z-index: 2;

  a {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin: 0 50px;
    width: calc(20vw - 100px);
    // text-decoration: underline 2px;

    &:hover {
      color: $colourP;
    }
  }
}

#onDemand {
  min-height: 100vh;
  margin-top: 20px;

  header {
    #logo {
      width: 80px;
      margin: 20px auto;
      display: block;
    }

    ion-segment {
      ion-segment-button {
        text-transform: capitalize;
      }
    }
  }

  #searchBar {
    width: calc(100% - 50px);
    box-sizing: border-box;
    max-width: 500px;
    margin: auto;
    padding: 5px 10px;
    border: solid 1px #ccc;
    border-radius: 15px;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      font-size: 1em;

      &:focus-visible {
        outline: none;
      }
    }

    i {
      font-size: 1.2em;
      margin-right: 10px;
      color: $colourP;
      cursor: pointer;
    }
  }

  #search-i {
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
  }
}

#openCollection {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100vw;
  max-width: unset;
  height: 0px;
  margin: 0;
  background-color: white;
  transition: height 0.3s ease-in-out;
  z-index: 3;
  overflow: hidden;

  &.open {
    height: calc(100vh - 100px);
    overflow-y: auto;
  }

  #openCollectionHeader {
    position: sticky;
    top: -5px;
    background-color: white;
    padding: 5px 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    h2 {
      color: $colourP;
      font-weight: 600;
    }

    a {
      font-size: 1.2em;
      color: $colourT;
      cursor: pointer;
    }
  }

  .videoList {
    height: unset;
    max-height: unset;
  }
}

@media (max-width: 800px) {
  #onDemand {
    margin-top: 20px;

    section {
      margin: 20px auto;

      .videoList .open {
        flex-direction: column;
        overflow-x: hidden;
      }
    }
  }
}
</style>